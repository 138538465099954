/*--------------------------------------------------------------
# Execution
--------------------------------------------------------------*/

.execution-section {
  .section-title-wrap {
    .title-img:before {
      background: #dddddd;
    }
  }
    @media(max-width: 991px) {
        .single-ex-process {
            margin-bottom:30px;
        }
    }
  h4 {
      color: $section-subtitle-color;
      text-transform: uppercase;
      font-weight: bold;
  }
}