
//    Font Family

$primary-font	: 'Montserrat', sans-serif;

// --------------------------- Color variations ----------------------
$akaene-lighter : #0E8FB9;
$akaene-darker  : #26708E;

$primary-color	: #15baee; //#26708e;  
// $primary-color	: #ff7500;  //#

$primary-color2	: #15baee;

$title-color	: $akaene-lighter;
$section-title-color	: $akaene-darker;
$section-subtitle-color	: $akaene-lighter;

$text-color		: #50668f;
$aquablue-color : #edf6ff;

$white			: #fff;
$offwhite		: #f2f5fa;
$black			: #222;