
/*--------------------------------------------------------------
# Features 
--------------------------------------------------------------*/
.features-content {
    h4 {
        margin-bottom: 25px;
    }
    p {
        margin-bottom: 50px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.faq-section {
    .content-wrap {
        .details {
            max-width: 810px;
            max-width: 810px;
            margin: 0 auto;
        }
    }
    .faq-tabs {
        .nav-pills {
            .nav-item {
                .nav-link {
                    background-color: #edf6ff;
                    border: 1px solid #bed2f0;
                    text-transform: uppercase;
                    color: $black;
                    font-weight: 600;
                    font-size: 12px;
                    &:hover {
                        background-image: linear-gradient(0deg, #9a88ff 0%, #5a61ff 100%)!important;
                        background-image: -webkit-linear-gradient(0deg, #9a88ff 0%, #5a61ff 100%)!important;
                        background-image: -o-linear-gradient(0deg, #9a88ff 0%, #5a61ff 100%)!important;
                        background-image: linear-gradient(0deg, #9a88ff 0%, #5a61ff 100%)!important;
                        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
                        color: $white!important;
                    }
                }
                .nav-link.active {
                    background-image: linear-gradient(0deg, #9a88ff 0%, #5a61ff 100%)!important;
                    background-image: -webkit-linear-gradient(0deg, #9a88ff 0%, #5a61ff 100%)!important;
                    background-image: -o-linear-gradient(0deg, #9a88ff 0%, #5a61ff 100%)!important;
                    background-image: linear-gradient(0deg, #9a88ff 0%, #5a61ff 100%)!important;
                    color: $white!important;
                }
            }
        }
        .tab-content {
            .card {
                border: 0;
                .card-header {
                    background-color: transparent;
                    [data-toggle="collapse"] .fa:before {  
                        content: "\f063";
                      }

                      [data-toggle="collapse"].collapsed .fa:before {
                        content: "\f061";
                      }
                    .btn-link {
                        color: #222;
                        font-size: 18px;
                        i {
                            margin-right: 10px;
                        }
                    }
                }
                .card-body {
                    margin-left: 40px;
                }
            }
        }
    }
}