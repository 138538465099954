/*--------------------------------------------------------------
# Elements 
--------------------------------------------------------------*/

.section-gap {
    padding: 60px 0;
    @media(max-width: 991px) {
        padding: 60px 0px;
    }
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.aquablue-bg {
    background: $offwhite;
}

.gradient-bg {
    @extend .d1-gradient-bg;
}

.gradient-bg2 {
    @extend .d3-gradient-bg;
}

.gradient-bg4 {
    @extend .d4-gradient-bg;
}

.section-title-wrap {
    text-align: center;
    margin-bottom: 80px;
    .title-img {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 15px;
        &:before {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0px;
            @include transform(translateX(-50%));
            background: $offwhite;
            height: 3px;
            width: 80px;
        }
    }
    h1 {
        font-size: 36px;
        font-weight: 500;
        color: $section-title-color;
        font-weight: bold;
        text-transform: uppercase;
    }
    p {
        max-width: 820px;
        margin-left: auto;
        margin-right: auto;
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 0px;
    }
}

.d1-gradient-bg {
    background-image: -moz-linear-gradient( 110deg, $primary-color 0%, $primary-color2 100%);
    background-image: -webkit-linear-gradient( 110deg, $primary-color 0%, $primary-color2 100%);
    background-image: -ms-linear-gradient( 110deg, $primary-color 0%, $primary-color2 100%);
}

.d2-gradient-bg {
    background-image: -moz-linear-gradient( 0deg, rgb(95, 185, 253) 0%, rgb(114, 227, 166) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(95, 185, 253) 0%, rgb(114, 227, 166) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(95, 185, 253) 0%, rgb(114, 227, 166) 100%);
}

.d3-gradient-bg {
    background-image: -moz-linear-gradient(0deg, rgb(44, 0, 255) 0%, rgb(163, 82, 245) 100%);
    background-image: -webkit-linear-gradient(0deg, rgb(44, 0, 255) 0%, rgb(163, 82, 245) 100%);
    background-image: -ms-linear-gradient(0deg, rgb(44, 0, 255) 0%, rgb(163, 82, 245) 100%);
}

.d4-gradient-bg {
    background-image: -moz-linear-gradient(0deg, #9a88ff 0%, #5a61ff 100%);
    background-image: -webkit-linear-gradient(0deg, #9a88ff 0%, #5a61ff 100%);
    background-image: -ms-linear-gradient(0deg, #9a88ff 0%, #5a61ff 100%);
}

.v3-gradient-bg {
    background-image: -moz-linear-gradient( 0deg, rgb(20, 29, 162) 0%, rgb(139, 62, 251) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(20, 29, 162) 0%, rgb(139, 62, 251) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(20, 29, 162) 0%, rgb(139, 62, 251) 100%);
}

.genric-btn {
    background: $offwhite;
    color: $title-color;
    position: relative;
    display: inline-block;
    z-index: 1;
    max-width: 100%;
    padding: 0px 34px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    border: 0px;
    text-align: center;
    overflow: hidden;
    vertical-align: middle;
    white-space: nowrap;
    height: 50px;
    line-height: 50px;
    transition: .3s;
    cursor: pointer;
    &:hover {
        @extend .d1-gradient-bg;
        color: $white;
    }

}    

@include media-breakpoint-down(sm) { 

    .genric-btn {
        margin-right: 1rem !important;
    }
  
 }

.genric-btn2 {
    @extend .d1-gradient-bg;
    color: $white;
    position: relative;
    display: inline-block;
    z-index: 1;
    max-width: 100%;
    padding: 0px 34px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    text-align: center;
    overflow: hidden;
    vertical-align: middle;
    white-space: nowrap;
    height: 50px;
    line-height: 50px;
    transition: .3s;
    cursor: pointer;
    @include transition();
    &:hover {
        background: $offwhite;
        color: $title-color;
    }
}

.genric-btn3 {
    background-color: #ffffff;
    color: $title-color;
    border: 0px;
    padding: 0px 34px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    position: relative;
    display: inline-block;
    z-index: 1;
    max-width: 100%;
    font-weight: 600;
    border: none;
    text-align: center;
    overflow: hidden;
    vertical-align: middle;
    white-space: nowrap;
    cursor: pointer;
    height: 50px;
    line-height: 50px;
    @include transition();
    &:hover {
        &:hover {
            @extend .d1-gradient-bg;
            color: $white;
        }
    }
}

.genric-btn4 {
    @extend .d1-gradient-bg;
    color: $white;
    $light-purple: #9a88fe;
    position: relative;
    display: inline-block;
    z-index: 1;
    max-width: 100%;
    padding: 0px 34px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    text-align: center;
    overflow: hidden;
    vertical-align: middle;
    white-space: nowrap;
    height: 50px;
    line-height: 50px;
    transition: .1s;
    cursor: pointer;
    @include transition();
    &:hover {
        background: $light-purple;
        color: $white;
    }
}

.pr-btn2 {
    margin: 0px 5px;
    padding: 8px 25px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    @extend .d3-gradient-bg;
    border: 1px solid #bed2f0 !important;
    color: $white !important;
    &:hover {
        @extend .d3-gradient-bg;
        color: $white !important;
    }
    @media(max-width:413px) {
        font-size: 12px;
    }
}

.active2 {
    background: white;
    color: $black !important;
}

.border-top {
    border-top: 1px solid $aquablue-color;
}

.page-top-section {
    background: url(../img/footer/page-top-bg.jpg) center/cover;
    padding-top: 235px;
    padding-bottom: 40px;
    .page-top-nav {
        text-align: right;
        @media(max-width: 767px) {
            text-align:left;
            margin-top: 30px;
        }
        a {
            text-transform: uppercase !important;
            color: $white;
        }
        .lnr {
            color: $white;
            padding: 0px 10px;
            font-weight: 700;
        }
    }
}

.social-heading {
    max-width: 425px;
}

.mw-510 {
    max-width: 510px;
}

.cta-section {
    padding: 100px 0px;
    h1 {
        @media(max-width: 1199px)and(min-width: 991px) {
            font-size:30px;
        }
    }
    @media(max-width:991px) {
        .justify-content-end {
            justify-content: flex-start !important;
        }
        .cta-btn {
            margin-top: 30px;
        }
    }
}

    .ct-btn1 {
        background: #ffffff;
        color: $title-color;
        height: 48px;
        line-height: 46px;
        position: relative;
        display: inline-block;
        z-index: 1;
        font-weight: 600;
        max-width: 100%;
        padding: 0px 34px;
        font-size: 12px;
        text-transform: uppercase;
        max-width: 100%;
        font-weight: 600;
        border: 2px solid #ffffff;
        text-align: center;
        overflow: hidden;
        @include transition();
        &:hover {
            background: transparent;
            color: #fff;
        }
    }
    .ct-btn2 {
        background: transparent;
        color: #fff;
        height: 48px;
        line-height: 46px;
        position: relative;
        display: inline-block;
        z-index: 1;
        font-weight: 600;
        max-width: 100%;
        padding: 0px 34px;
        font-size: 12px;
        text-transform: uppercase;
        max-width: 100%;
        font-weight: 600;
        border: 2px solid #ffffff;
        text-align: center;
        overflow: hidden;
        @include transition();
        &:hover {
            background: #ffffff;
            color: $title-color;
        }
    }

.version1-1 {
    .ct-btn {
        margin: 0px 5px;
        @extend .d2-gradient-bg;
        color: $white;
        position: relative;
        display: inline-block;
        z-index: 1;
        font-weight: 600;
        max-width: 100%;
        padding: 8px 25px;
        font-size: 14px;
        text-transform: uppercase;
        border: 1px solid transparent;
        @include transition();
        &.active {
            background: rgba($white, .2);
            border-color: $white;
            @include transition();
            &:hover {
                @extend .d2-gradient-bg;
                border-color: transparent;
            }
        }
        @media(max-width:413px) {
            font-size: 12px;
        }
    }
}

.version2 {
    .ct-btn {
        margin: 0px 5px;
        background: $white;
        color: $black;
        position: relative;
        display: inline-block;
        z-index: 1;
        font-weight: 600;
        max-width: 100%;
        padding: 8px 25px;
        font-size: 14px;
        text-transform: uppercase;
        border: 1px solid transparent;
        @include transition();
        &.active {
            background: rgba($white, .2);
            border-color: $white;
            color: $white;
            @include transition();
            &:hover {
                background: $white;
                color: $black;
                border-color: $white;
            }
        }
        @media(max-width:413px) {
            font-size: 12px;
        }
    }
}

.version3 {
    .ct-btn {
        margin: 0px 5px;
        @extend .d2-gradient-bg;
        color: $white;
        position: relative;
        display: inline-block;
        z-index: 1;
        font-weight: 600;
        max-width: 100%;
        padding: 8px 25px;
        font-size: 14px;
        text-transform: uppercase;
        border: 1px solid transparent;
        @include transition();
        &.active {
            background: rgba($white, .2);
            border-color: $white;
            @include transition();
            &:hover {
                @extend .d2-gradient-bg;
                border-color: transparent;
            }
        }
        @media(max-width:413px) {
            font-size: 12px;
        }
    }
}

.version4 {
    .ct-btn {
        margin: 0px 5px;
        background: $white;
        color: $black;
        position: relative;
        display: inline-block;
        z-index: 1;
        font-weight: 600;
        max-width: 100%;
        padding: 8px 25px;
        font-size: 14px;
        text-transform: uppercase;
        border: 1px solid transparent;
        @include transition();
        &.active {
            background: rgba($white, .2);
            border-color: $white;
            color: $white;
            @include transition();
            &:hover {
                background: $white;
                color: $black;
                border-color: $white;
            }
        }
        @media(max-width:413px) {
            font-size: 12px;
        }
    }
}

.version7 {
    .ct-btn {
        margin: 0px 5px;
        background: $white;
        color: $black;
        position: relative;
        display: inline-block;
        z-index: 1;
        font-weight: 600;
        max-width: 100%;
        padding: 8px 25px;
        font-size: 14px;
        text-transform: uppercase;
        border: 1px solid transparent;
        @include transition();
        &.active {
            background: rgba($white, .2);
            border-color: $white;
            color: $white;
            @include transition();
            &:hover {
                background: $white;
                color: $black;
                border-color: $white;
            }
        }
        @media(max-width:413px) {
            font-size: 12px;
        }
    }
}

.version8 {
    .ct-btn {
        margin: 0px 5px;
        background: $white;
        color: $black;
        position: relative;
        display: inline-block;
        z-index: 1;
        font-weight: 600;
        max-width: 100%;
        padding: 8px 25px;
        font-size: 14px;
        text-transform: uppercase;
        border: 1px solid transparent;
        @include transition();
        &.active {
            background: rgba($white, .2);
            border-color: $white;
            color: $white;
            @include transition();
            &:hover {
                background: $white;
                color: $black;
                border-color: $white;
            }
        }
        @media(max-width:413px) {
            font-size: 12px;
        }
    }
}

.version9 {
    .ct-btn {
        margin: 0px 5px;
        background: $white;
        color: $black;
        position: relative;
        display: inline-block;
        z-index: 1;
        font-weight: 600;
        max-width: 100%;
        padding: 8px 25px;
        font-size: 14px;
        text-transform: uppercase;
        border: 1px solid transparent;
        @include transition();
        &.active {
            background: rgba($white, .2);
            border-color: $white;
            color: $white;
            @include transition();
            &:hover {
                background: $white;
                color: $black;
                border-color: $white;
            }
        }
        @media(max-width:413px) {
            font-size: 12px;
        }
    }
}

.stat-section {
    .section-title-wrap {
        margin-bottom: 20px;
    }
}

.single-stat {
    margin-top: 60px;
    h4 {
        margin-top: 40px;
        margin-bottom: 20px;
        font-weight: 500;
        color: #1e283c;
    }
    p {
        max-width: 320px;
        margin-bottom: 0px;
    }
}

.active-dash-carusel,
.social-right {
    @media(max-width: 991px) {
        margin-top:50px;
    }
}
.social-section {
	h1 {
		font-size: 48px;
		font-weight: 500;
	}
	p {
		padding: 30px 0px;
    max-width: 500px;
	}
}

.grecaptcha-badge {
    visibility: hidden;
}

.side-image {
    text-align: center;
    @media(max-width: 991px) {
        margin-top: 50px;
        margin-bottom:-150px;
    }
}