/*--------------------------------------------------------------
# Feature
--------------------------------------------------------------*/

.feature-list {
    margin-bottom: 30px;
    &:last-child {
        margin-bottom: 0px;
    }
    .desc {
        p {
            max-width: 400px;
        }
    }
    @media(max-width:991px) {
        margin-top: 50px;
    }
}

.single-feature {
    margin-top: 0px;
    border: 2px solid transparent;
    padding: 48px 38px;
    // @include transition();
    height: 100%;
    text-align:center;
    .desc {
        h4 {
            color: $section-subtitle-color;
            text-transform: uppercase;
            font-weight: bold;
			font-size: 20px;
			margin-top: 0px;
			margin-bottom: 18px;
		}
		p {
            text-align: left;
            height: 300px;
		}
    }
    &:hover {
        background: #fff;
        border: 2px solid $primary-color;
		.icon {
			span {
				background: $offwhite;
			}
		}
    }
}

@media(max-width: 991px) {
    .feature-right {
        margin-top: 50px;
        text-align: center !important;
    }
    .active-feature-carusel {
        margin-bottom: 50px;
    }
}

@media(max-width:768px) {
    .feature-right {
        p {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}